import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";
import CoffeeSlider from "../components/CoffeeSlider";

const LayoutWrapper = styled.div``;

const pageData = {
  header: "Kawa",
  mainTitle: "Zasmakuj kawy",
  subtitle: "Speciality Salvatti",
};

const slidesData = [
  {
    image: require("../assets/images/coffees/akagera.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/akagera-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,

    beans: {
      aroma: "kardamon, cytrusy",
      normal: {
        black: 2,
        color: 0,
        white: 1,
      },
      light: {
        black: 0,
        color: 0,
        white: 0,
      },
      intensity: {
        black: 5,
        color: 0,
        white: 0,
      },
    },
    alt: "Akagera",
    title: "Akagera -",
    description:
      "specjalnie wyselekcjonowane owoce, uprawiane w regionie dzikiego Parku Akagera położonego pośród malowniczych wzgórz Rwandy. Kawa Akagera jest ceniona przez koneserów z powodu nietypowego smaku. Początkowo cierpka, o szczególnie poszukiwanym dzisiaj posmaku egzotycznych owoców, subtelnie zmienia się w ustach, pozostawiając przejrzysty smak kardamonu.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/chemex.webp").default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "chemex",
      alternativeDesc3: "cisnienowy",
      alternativeDesc4: "drip",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "przelewowy",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/akagera.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/akagera/1/phot/5?url=akagera",
      txt: "Akagera",
    },
  },
  {
    image: require("../assets/images/coffees/kizi-rift.webp").default,
    imageName:
      require("../assets/images/coffees/coffe-names/kizi-rift-name.webp")
        .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "orzeszki arachidowe, orzechy kwitnące zioła",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 1,
        color: 2,
        white: 0,
      },
      intensity: {
        black: 2,
        color: 3,
        white: 0,
      },
    },
    alt: "Kizi Rift",
    title: "Kizi Rift -",
    description:
      "kawa uprawiana w środkowej części Rwandy, w regionie doliny Kizi Rift. Zachwyca orzechowo – owocowym smakiem. Kawa Kizi Rift należy do najszlachetniejszego gatunku kaw Arabica. Rośnie na wysokości 2000 – 2200 m n.p.m., w centralnej części Rwandy. Jej plantacje rozciągają się od lasu deszczowego Nyingwe do wulkanicznego pasma Virunga. O łagodnym smaku orzechów i kwitnących ziół jest ulubionym napojem kobiet.",
    lightBox: {
      header: "Kizi Rift – light",
      text: "Kawa Kizi Rift jest wyjątkowa ze względu na swój subtelny smak. Jasno palona pozwala jeszcze wyraźniej wyczuć orzechy z delikatną nutą kwiatową. Zalecana do metod przelewowych i alternatywnych. Kawa jasno palona zachowuje naturalna owocowość i jest delikatna w smaku.",
    },
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "cisnienowy",
      alternativeDesc4: "drip",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "przelewowy",
    },

    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/kizi-rift.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/kizi+rift/1/phot/5?url=kizi,rift",
      txt: "Kizi Rift",
      image2:
        require("../assets/images/coffees/coffeeProducts/kizi-rift-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/kizi+rift+jasno+palona/1/phot/5?url=kizi,rift,jasno,palona",
      txt2: "Kizi Rift Light",
    },
  },
  {
    image: require("../assets/images/coffees/gashonga.webp").default,
    imageName:
      require("../assets/images/coffees/coffe-names/gashonga-name.webp")
        .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "marcepan, świeża limonka, konfitura ślliwkowa",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 1,
        color: 2,
        white: 0,
      },
      intensity: {
        black: 3,
        color: 2,
        white: 0,
      },
    },
    alt: "Gashonga",
    title: "Gashonga -",
    description:
      "kawa jest uprawiana na wysokości 1683 m n.p.m. w zachodniej części Rwandy, nieśmiało wciśniętej między Kongo a Burundi. Uprawy tej kawy nię są rozległe, dlatego należy ona do rarytasów na światowym rynku. O jej unikatowości stanowi nadzwyczajna nuta marcepanowa z mocą słodyczy. Kawa jest wielobarwna w smaku, z delikatną smugą słodko-kwaskowatej morwy, śliwkowej konfitury i skórki cytryny.",
    lightBox: {
      header: "Gashonga - light",
      text: "Jasno palona kawa Gashonga jest szczególnie lubiana przez koneserów z powodu unikatowej kompozycji smaków. Nuty marcepanu i cytrusów nadają kawie delikatnie orzeźwiający posmak. Idealnie sprawdzi się w metodach przelewowych i alternatywnych.",
    },
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/chemex.webp").default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "chemex",
      alternativeDesc5: "przelewowy",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/gashonga.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/gashonga/1/phot/5?url=gashonga",
      txt: "Gashonga",
      image2:
        require("../assets/images/coffees/coffeeProducts/gashonga-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/gashonga+jasno+palona/1/phot/5?url=gashonga,jasno,palona",
      txt2: "Gashonga Light",
    },
  },

  {
    image: require("../assets/images/coffees/gisuma.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/gisuma-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "pomarańcza, afrykańskie jagody",
      normal: {
        black: 2,
        color: 0,
        white: 1,
      },
      light: {
        black: 1,
        color: 0,
        white: 2,
      },
      intensity: {
        black: 3,
        color: 0,
        white: 2,
      },
    },
    alt: "Gisuma",
    title: "Gisuma -",
    description:
      "rośnie na wysokości 1700 m n.p.m., w okolicy Parku Narodowego Nyungwe, z obfitością afrykańskiej fauny i flory. Wilgotny górski klimat z dynamiką mocnego słońca i orzeźwiającego deszczu nadają smakowi kawy słodkie brzmienie, czarnych jagód, które urozmaicają orzeźwienie akcenty świeżej, słodkiej pomarańczy.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/chemex.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "chemex",
      alternativeDesc6: "kawiarka",
    },
    lightBox: {
      header: "Gisuma - light",
      text: "Jasno palona kawa Gisuma subtelnie orzeźwia nutą pomarańczy i uspokaja słodyczą jagód. Umiejętnie przeprowadzony proces parzenia uwydatnia naturalną słodycz owoców. Zalecana do metod przelewowych i alternatywnych. Kawa jasno palona zachowuje naturalną owocowość i jest delikatniejsza w smaku.",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/gisuma.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/gisuma/1/phot/5?url=gisuma",
      txt: "Gisuma",
      image2:
        require("../assets/images/coffees/coffeeProducts/gisuma-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/gisuma+jasno+palona/1/phot/5?url=gisuma,jasno,palona",
      txt2: "Gisuma Light",
    },
  },
  {
    image: require("../assets/images/coffees/muhazi.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/muhazi-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "kakao, czarna herbata",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 1,
        color: 2,
        white: 0,
      },
      intensity: {
        black: 4,
        color: 1,
        white: 0,
      },
    },
    alt: "Muhazi",
    title: "Muhazi -",
    description:
      "jest uprawiana na wysokości 1600 m n.p.m.. Czysta źródlana woda i wulkaniczna gleba okolic jeziora Muhazi sprawiają, że drzewa kawy gatunku Arabica osiągają wysokość większą niż w innych miejscach Rwandy. Ziarna kawy Muhazi mają w smaku niespotykaną słodko-gorzką nutę, pozwalającą wyczuć ślady kakao i mocnej czarnej herbaty jednocześnie. Plantacje tej kawy rozciągają się we wchodniej części Rwandy, na wysokości 1700 m n.p.m.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/chemex.webp").default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "french press",
      alternativeDesc2: "chemex",
      alternativeDesc3: "przelewowy",
      alternativeDesc4: "kawiarka",
      alternativeDesc5: "ciśnieniowy",
    },
    lightBox: {
      header: "Muhazi - light",
      text: "Jasno palona kawa Muhazi cechuje się unikatowym bukietem aromatów. Delikatny akcent kakao i czarnej herbaty rozbudza zmysł smaku. Idealna dla osób szukających w świecie kawy nowych, niebanalnych wrażeń. Zalecana do metod przelewowych i alternatywnych. Kawa jasno palona zachowuje naturalną owocowość i jest delikatniejsza w smaku.",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/muhazi.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/muhazi/1/phot/5?url=muhazi",
      txt: "Muhazi",
      image2:
        require("../assets/images/coffees/coffeeProducts/muhazi-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/muhazi+jasno+palona/1/phot/5?url=muhazi,jasno,palona",
      txt2: "Muhazi Light",
    },
  },
  {
    image: require("../assets/images/coffees/kivu-lake.webp").default,
    imageName:
      require("../assets/images/coffees/coffe-names/kivu-lake-name.webp")
        .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "czekolada, orzeszki macadamia",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 1,
        color: 2,
        white: 0,
      },
      intensity: {
        black: 3,
        color: 2,
        white: 0,
      },
    },
    alt: "Kivu Lake",
    title: "Kivu Lake -",
    description:
      "dojrzewająca i suszona w afrykańskim słońcu, jest najbardziej pożądaną kawą przez światowych koneserów. Rośnie na wysokości 1800 – 2000 m n.p.m., na wzgórzach wulkanicznych. Wyselekcjonowane owoce kawy są poddawane naturalnej fermentacji. Jezioro Kivu, wokół którego ścielą się plantacje kawy, rozlewa się na granicy Rwandy i Demokratycznej Republiki Kongo, w najwyżej położonej części Doliny Wielkiego Ryftu. Nazywana królową kaw, jest bardzo rozchwytywana.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    lightBox: {
      header: "Kivu Lake - light",
      text: "Jasno palona kawa Kivu Lake odróżnia się od kaw jasno palonych, które przejawiają nuty owocowe i cytrusowe. Kivu Lake ma subtelny posmak czekolady, który idealnie komponuje się z nutami orzechowymi. Zalecana do metod przelewowych i alternatywnych.",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/kivu-lake.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/kivu+lake/1/phot/5?url=kivu,lake",
      txt: "Kivu Lake",
      image2:
        require("../assets/images/coffees/coffeeProducts/kivu-lake-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/kivu+lake+jasno+palona/1/phot/5?url=kivu,lake,jasno,palona",
      txt2: "Kivu Lake Light",
    },
  },
  {
    image: require("../assets/images/coffees/amasimbi.webp").default,
    imageName:
      require("../assets/images/coffees/coffe-names/amasimbi-name.webp")
        .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "owoce, miód, masło orzechowe",
      natural: "obróbka natural",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 1,
        color: 2,
        white: 0,
      },
      intensity: {
        black: 3,
        color: 2,
        white: 0,
      },
    },
    alt: "Amasimbi",
    title: "Amasimbi -",
    description:
      "kawa pochodzi z żyznych regionów wulkanicznych północnej Rwandy. Charakteryzuje się dużą słodyczą, która wynika z naturalnej metody obróbki. Świeżo zebrane owoce kawowca są selekcjonowane, a następnie przez 30 dni leżakują w słońcu, by do ziaren mogły przeniknąć aromaty z owocowego miąższu.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    lightBox: {
      header: "Amasimbi - light",
      text: "Jasno palona kawa Amasimbi pochodzi z żyznych regionów wulkanicznych północnej Rwandy. Cechuje się unikatowym bukietem aromatów. Delikatny posmak owoców, miodu i masła orzechowego rozbudza zmysł smaku. Idealna dla osób szukających w świecie kawy nowych, niebanalnych wrażeń. Zalecana do metod przelewowych i alternatywnych. Kawa jasno palona zachowuje naturalną owocowość i jest delikatniejsza w smaku.",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/amasimbi.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/amasimbi/1/phot/5?url=amasimbi",
      txt: "Amasimbi",
    },
  },
  {
    image: require("../assets/images/coffees/virunga.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/virunga-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "słodkie cytrusy, owoce leśne, przyprawy korzenne",
      normal: {
        black: 2,
        color: 0,
        white: 1,
      },
      light: {
        black: 1,
        color: 0,
        white: 2,
      },
      intensity: {
        black: 5,
        color: 0,
        white: 0,
      },
    },
    alt: "Virunga",
    title: "Virunga -",
    description:
      "jest uprawiana na wysokości prawie 2500 m n.p.m., na wzgórzach wulkanicznych, które są mieszkaniem najbardziej atrakcyjnych zwierząt w tym regionie - goryli. Posiada nietypowy smak cytrusów oraz owoców leśnych z wibrującą nutą słodyczy. Mocna i wyrazista w smaku.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    lightBox: {
      header: "Virunga - light",
      text: "Jasno palona kawa Virunga jest idealna dla osób poszukujących ciekawych doznań. Bardzo dobrze sprawdzi się w metodach alternatywnych jak i przelewowych.",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/virunga.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/virunga/1/phot/5?url=virunga",
      txt: "Virunga",
      image2:
        require("../assets/images/coffees/coffeeProducts/virunga-light.webp")
          .default,
      href2:
        "https://jbm.pl/pl/searchquery/virunga+jasno+palona/1/phot/5?url=virunga,jasno,palona",
      txt2: "Virunga Light",
    },
  },
  {
    image: require("../assets/images/coffees/karambi.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/karambi-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "orzeszki pecan",
      normal: {
        black: 2,
        color: 0,
        white: 1,
      },
      light: {
        black: 0,
        color: 0,
        white: 0,
      },
      intensity: {
        black: 4,
        color: 0,
        white: 1,
      },
    },
    alt: "Karambi",
    title: "Karambi -",
    description:
      "delikatna kawa, uprawiana na południu Rwandy, w dystrykcie Nyamagabe, sąsiadującym z dzikim i wilgotnym parkiem Nyungwe. Południowe słońce zabarwia kawę nietypową, subtelną nutą orzechów pecan. Starannie wyselekcjonowane owoce sprawiają, że kawa ma jednorodny, przejrzysty smak. Jest uwielbiana przez kobiety, które cenią sobie subtelny smak i czysty, przejrzysty aromat.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/karambi.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/karambi/1/phot/5?url=karambi",
      txt: "Karambi",
    },
  },
  {
    image: require("../assets/images/coffees/cyahinda.webp").default,
    imageName:
      require("../assets/images/coffees/coffe-names/cyahinda-name.webp")
        .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "pieczony kasztan, karmel",
      normal: {
        black: 2,
        color: 0,
        white: 1,
      },
      light: {
        black: 0,
        color: 0,
        white: 0,
      },
      intensity: {
        black: 4,
        color: 0,
        white: 1,
      },
    },
    alt: "Cyahinda",
    title: "Cyahinda -",
    description:
      "Kawa wywodzi swoją nazwę od żyznego dystryktu Cyahinda, leżącego w południowej prowincji Nyaruguru. Jest uprawiana w górach, ciągnących się wzdłuż granicy Rwandy i Burundi. Sąsiedztwo dzikiej przyrody i górski klimat nadają kawie łagodny smak pieczonych kasztanów, urozmaicony karmelowym akcentem.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/cyahinda.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/cyahinda/1/phot/5?url=cyahinda",
      txt: "Cyahinda",
    },
  },
  {
    image: require("../assets/images/coffees/tys-wzg.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/tys-wzg-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "czekolada, orzechy",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 0,
        color: 0,
        white: 0,
      },
      intensity: {
        black: 4,
        color: 1,
        white: 0,
      },
    },
    alt: "Tysiąca Wzgórz",
    title: "Tysiąca Wzgórz -",
    description:
      "kawa jest uprawiana w wysokich górach Rwandy. Strome zbocza, na których rozpościerają się plantacje optymalizują dopływ promieni słonecznych. Dzięki temu kawa dojrzewa powoli i jest w pełni nasycona smakiem i aromatem. Kawa Tysiąca Wzgórz to 100% Arabica, ręcznie zbierana i selekcjonowana. Jest palona manufakturową metodą mistrzowską w piecu bębnowym, co sprawia, że zachwyca smakiem i długo zachowuje świeżość.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,
      imgAlternatives7:
        require("../assets/images/coffees/alternatives/chemex.webp").default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
      alternativeDesc7: "chemex",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/tys-wzg.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/tysi%C4%85ca+wzg%C3%B3rz/1/phot/5?url=tysi%C4%85ca,wzg%C3%B3rz",
      txt: "Tysiąca Wzgórz",
    },
  },
  {
    image: require("../assets/images/coffees/cyondo.webp").default,
    imageName: require("../assets/images/coffees/coffe-names/cyondo-name.webp")
      .default,
    imgCoffeeBeanBlack: require("../assets/images/coffees/coffeBean_black.webp")
      .default,
    imgCoffeeBeanColor: require("../assets/images/coffees/coffeBean_color.webp")
      .default,
    imgCoffeeBeanWhite:
      require("../assets/images/coffees/coffeeBean_white.webp").default,
    beans: {
      aroma: "czekolada, chili",
      normal: {
        black: 2,
        color: 1,
        white: 0,
      },
      light: {
        black: 0,
        color: 0,
        white: 0,
      },
      intensity: {
        black: 5,
        color: 0,
        white: 0,
      },
    },
    alt: "Cyondo",
    title: "Cyondo -",
    description:
      "jest uprawiana na wysokości 2000 m n.p.m., na żyznych glebach w regionie Rutsiro, w zachodniej części Rwandy. Dla arabiki to znakomite warunki. Bogate w minerały gleby i umiarkowane nasłonecznienie sprawiają, że w kawie uwidacznia się wyraźnie nuta czekolady, którą przełamuje pikantny akord chilli.",
    alternatives: {
      imgAlternatives1:
        require("../assets/images/coffees/alternatives/aeropress_main.webp")
          .default,
      imgAlternatives2:
        require("../assets/images/coffees/alternatives/frenchpress.webp")
          .default,
      imgAlternatives3:
        require("../assets/images/coffees/alternatives/drip.webp").default,
      imgAlternatives4:
        require("../assets/images/coffees/alternatives/przelewowy.webp")
          .default,
      imgAlternatives5:
        require("../assets/images/coffees/alternatives/kawiarka.webp").default,
      imgAlternatives6:
        require("../assets/images/coffees/alternatives/cisnienowy.webp")
          .default,

      alternativeDesc1: "aeropress",
      alternativeDesc2: "french press",
      alternativeDesc3: "drip",
      alternativeDesc4: "przelewowy",
      alternativeDesc5: "kawiarka",
      alternativeDesc6: "ciśnieniowy",
    },
    coffeeProd: {
      image: require("../assets/images/coffees/coffeeProducts/cyondo.webp")
        .default,
      href: "https://jbm.pl/pl/searchquery/cyondo/1/phot/5?url=cyondo",
      txt: "Cyondo",
    },
  },
];

const CoffeeSection = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`coffee-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>

      <Layout>
        {/* <div className="layoutWrapper"> */}

        <section className="coffee-page">
          <LayoutWrapper>
            <Seo title="Sekcja kawy" />

            <div className="coffeeSectionMain">
              <div className="coffeeSectionWrapper">
                <div className="header">
                  <div className="headerImg">
                    <img
                      src={
                        require("../assets/images/coffees/coffeeSection/headerImg.webp")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <img
                    className="coffeeLeaf-1"
                    src={
                      require("../assets/images/coffees/coffeeSection/coffeeLeaf.webp")
                        .default
                    }
                    alt=""
                  />
                  <img
                    className="coffeeLeaf-2"
                    src={
                      require("../assets/images/coffees/coffeeSection/coffeeLeaf.webp")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="txt mobileTxt">
                  <h2>Bukiet Kawowy</h2>
                  <p>100% Arabica Single Origin</p>
                </div>
                <CoffeeSlider slides={slidesData} />
                <div className="middleTxt speciality">
                  <div className="middleTxtWrapper">
                    <h2>Czym jest Kawa Speciality?</h2>
                    <p>
                      Wyrażenie "speciality coffee” pierwszy raz zostało użyte w
                      1974 roku przez Ernę Knutsen w Tea &amp; Coffee Trade
                      Journal. Pani Knutsen użyła tego sformułowania w celu
                      określenia najlepszych ziaren, produkowanych w specjalnych
                      warunkach mikroklimatycznych. Kawy te miały być pozbawione
                      defektów i charakteryzować się najwyższą jakością. Obecnie
                      kawy speciality to takie, które uzyskują 80 i więcej
                      punktów w stupunktowej skali. Punkty przyznają specjalnie
                      wykwalifikowani “somelierzy” kawy. Kawa speciality podlega
                      najwyższej jakości obróbce. Cały proces produkcji, który
                      rozpoczyna się już na plantacji, poprzez zbiory, płukanie,
                      suszenie, selekcjonowanie ziaren po wypalenie oraz
                      parzenie samej kawy jest wysoce sprofesjonalizowany.
                    </p>
                    <img
                      src={
                        require("../assets/images/coffees/coffeeSection/coffee_cup.webp")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="middleTxtContainer">
                  <div className="middleTxt arabica100">
                    <h2>100% ARABICA</h2>
                    <p>
                      Kawa Arabica jest uprawiana głównie w strefie
                      międzyzwrotnikowej, gdzie panują odpowiednie warunki
                      klimatyczne dla tej rośliny. Rośnie na wysokościach od
                      około 600 do 2000 m n.p.m.. Wymaga dużo opieki i uwagi, a
                      zbieranie kawy Arabica jest czasochłonne i wymaga ręcznego
                      zbioru. Arabica jest znana ze swojego delikatnego,
                      łagodnego smaku i aromatu, który jest uważany za bardziej
                      subtelny niż smak kawy Robusta. Kawa Arabica zawiera mniej
                      kofeiny niż kawa Robusta. Średnio kawa Arabica zawiera
                      około 1% kofeiny, co oznacza, że ma około połowę
                      zawartości kofeiny w porównaniu do kawy Robusta. Ze
                      względu na wymagający proces uprawy i zbioru, kawa Arabica
                      jest zwykle droższa niż kawa Robusta. Wysokiej jakości
                      Arabica może kosztować nawet kilkanaście razy więcej niż
                      najtańsza kawa Robusta.
                    </p>
                  </div>
                  <div className="mapImg">
                    <div className="txt">
                      <h1>RWANDA</h1>
                      <p>Region uprawy definiuje smak</p>
                      <img
                        className="coffeeLeaf-1"
                        src={
                          require("../assets/images/coffees/coffeeSection/coffeeLeaf.webp")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <img
                      className="map-img"
                      src={
                        require("../assets/images/coffees/coffeeSection/mapka.webp")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="middleTxt redBurbon">
                    <h2>Red Bourbon</h2>
                    <p>
                      Rośliny Bourbon słyną z produkcji wysokiej jakości ziaren
                      kawy Arabica, które są bardzo cenione wśród miłośników
                      kawy za swoje bogate i złożone profile smakowe. Kawa Red
                      Bourbon jest zwykle prażona na jasno lub średnio, aby
                      zachować jej wyjątkowe smaki i aromaty. Charakteryzuje się
                      bogatym smakiem, który łączy nuty cytrusowe z subtelnymi
                      nutami karmelu i czekolady. Kawa w Rwandzie jest uprawiana
                      głównie w małych gospodarstwach rolnych, gdzie stosuje się
                      tradycyjne metody uprawy. Warto pamiętać, że smak i
                      właściwości kawy Arabica Red Bourbon mogą się różnić w
                      zależności od regionu, w którym została uprawiona oraz
                      sposobu jej prażenia i parzenia. Jest to odmiana kawy o
                      niskiej wydajności zbiorów, ale wysokiej jakości, co
                      sprawia, że jest to zwykle kawa premium.
                    </p>
                  </div>
                </div>
                <div className="coffeeMission">
                  <div className="leftCol">
                    <h2>Czym jest Kawa Speciality?</h2>
                    <p>
                      Wyrażenie "speciality coffee” pierwszy raz zostało użyte w
                      1974 roku przez Ernę Knutsen w Tea &amp; Coffee Trade
                      Journal. Pani Knutsen użyła tego sformułowania w celu
                      określenia najlepszych ziaren, produkowanych w specjalnych
                      warunkach mikroklimatycznych. Kawy te miały być pozbawione
                      defektów i charakteryzować się najwyższą jakością. Obecnie
                      kawy speciality to takie, które uzyskują 80 i więcej
                      punktów w stupunktowej skali. Punkty przyznają specjalnie
                      wykwalifikowani “somelierzy” kawy. Kawa speciality podlega
                      najwyższej jakości obróbce. Cały proces produkcji, który
                      rozpoczyna się już na plantacji, poprzez zbiory, płukanie,
                      suszenie, selekcjonowanie ziaren po wypalenie oraz
                      parzenie samej kawy jest wysoce sprofesjonalizowany.
                    </p>
                    <img
                      src={
                        require("../assets/images/coffees/coffeeSection/coffee_cup.webp")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="rightCol">
                    <div id="mainSliderSpecial" className="elements-list">
                      <article className="element">
                        <div className="rightColWrapper">
                          <img
                            className="desktopImg"
                            src={
                              require("../assets/images/coffees/coffeeSection/coffeeMission_3.webp")
                                .default
                            }
                          />

                          <div className="txt">
                            <h2>Kawa z Misją</h2>
                            <p>
                              Kupując kawę wspierasz lokalnych plantatorów!
                              Dochód ze sprzedaży jest przeznaczony na rozwój
                              edukacji i opieki medycznej głównie w krajach
                              Afryki, Ameryki Południowej i Azji, gdzie pracują
                              misjonarze. Wspieramy prowadzone przez nich
                              dzieła, przedszkola, szkoły, szpitale i ośrodki
                              zdrowia.
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
                <div className="coffeeChart">
                  <img
                    className="coffeeChartDesktop"
                    src={
                      require("../assets/images/coffees/coffeeSection/coffeeWykresDesktop.webp")
                        .default
                    }
                    alt=""
                  />
                  <img
                    className="coffeeChartMobile"
                    src={
                      require("../assets/images/coffees/coffeeSection/coffeeWykresMobile.webp")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="alternativeMethods">
                  <h2 style={{ textAlign: "center" }}>
                    Dowiedz się więcej o alternatywnych metodach parzenia:
                  </h2>
                  <div className="altenativesBox">
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#aeropressId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/aeropress_main.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>aeropress</p>
                    </div>
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#frenchPressId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/frenchpress.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>French Press</p>
                    </div>
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#dripId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/drip.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>Drip</p>
                    </div>
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#przelewowyId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/przelewowy.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>Przelewowy</p>
                    </div>
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#chemexId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/chemex.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>Chemex</p>
                    </div>
                    <div>
                      <a
                        href="/alternatywne-sposoby-parzenia-kawy?preview=true#kawiarkaId"
                        target="”_blank”"
                      >
                        <img
                          src={
                            require("../assets/images/coffees/alternatives/kawiarka.webp")
                              .default
                          }
                          alt=""
                        />
                      </a>
                      <p>Kawiarka</p>
                    </div>
                  </div>
                </div>
                <div className="coffeeFooter">
                  <div className="coffeeFooterContainer">
                    <div className="leftCol">
                      <div className="leftColWrapper">
                        <a href="/pl_PL/i/Coffee-Section-main/77?preview=true">
                          <img
                            src={
                              require("../assets/images/coffees/coffeeSection/logo.webp")
                                .default
                            }
                            alt=""
                          />
                        </a>
                        <h2>
                          Ambasada Kaw Salvatti <br />
                          ul. Brzeska 107A <br />
                          21-500 Biała Podlaska
                        </h2>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="socials">
                        <div className="socialsWrapper">
                          <div className="socialsContentWrapper">
                            <img
                              src={
                                require("../assets/images/coffees/coffeeSection/tel.webp")
                                  .default
                              }
                              alt=""
                            />
                            <p>
                              tel:
                              <a href="tel:+48833447005"> +48 83 344 70 05</a>
                            </p>
                          </div>
                          <div className="socialsContentWrapper">
                            <img
                              src={
                                require("../assets/images/coffees/coffeeSection/tel.webp")
                                  .default
                              }
                              alt=""
                            />
                            <p>
                              kom:
                              <a href="tel:+48885110114"> +48 885 110 114</a>
                            </p>
                          </div>
                          <div className="socialsContentWrapper">
                            <a
                              href="https://www.facebook.com/SpecialitySalvatti"
                              target="”_blank”"
                            >
                              <img
                                src={
                                  require("../assets/images/coffees/coffeeSection/fb.png")
                                    .default
                                }
                                alt=""
                              />
                            </a>
                            <p>Kawa z misją - Speciality Salvatti</p>
                          </div>
                          <div className="socialsContentWrapper">
                            <a
                              href="https://www.instagram.com/salvatti.jbm/"
                              target="”_blank”"
                            >
                              <img
                                src={
                                  require("../assets/images/coffees/coffeeSection/insta.png")
                                    .default
                                }
                                alt=""
                              />
                            </a>
                            <p>salvatti.jbm</p>
                          </div>
                        </div>
                      </div>
                      <div className="footerLogoContainer">
                        <a
                          href="http://jbmultimedia.pl/language/pl/home/"
                          target="”_blank”"
                        >
                          <img
                            src={
                              require("../assets/images/coffees/coffeeSection/jbmultimedia_logo.webp")
                                .default
                            }
                            alt=""
                          />
                        </a>
                        <img
                          src={
                            require("../assets/images/coffees/coffeeSection/logo2.webp")
                              .default
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapper>
        </section>

        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo title="Sekcja kawy" description="Salvatti - kawa z misją" />
);

export default CoffeeSection;
