import React from "react";

function CoffeeWrapper({ slide }) {
  const renderBeans = (count, src, keyPrefix) =>
    Array.from({ length: count }, (_, i) => (
      <img src={src} alt={`${keyPrefix}`} key={`${keyPrefix}-${i}`} />
    ));

  const flavor = [];

  return (
    <div>
      <p class="aroma">
        <strong>Nuty smakowe: </strong>
        {slide.beans.aroma}
      </p>
      <p className="natural"> {slide.beans.natural}</p>

      <div className="bean-category normal">
        <p>Stopień palenia:</p>
        <div className="bean-category-wrapper">
          {renderBeans(
            slide.beans.normal.black,
            slide.imgCoffeeBeanBlack,
            "coffee-beans black"
          )}
          {renderBeans(
            slide.beans.normal.color,
            slide.imgCoffeeBeanColor,
            "normal-color"
          )}
          {renderBeans(
            slide.beans.normal.white,
            slide.imgCoffeeBeanWhite,
            "normal-white"
          )}
        </div>
      </div>
      {slide.beans.light.black ? (
        <div className="bean-category light">
          <p>Stopień palenia light:</p>
          <div className="bean-category-wrapper">
            {renderBeans(
              slide.beans.light.black,
              slide.imgCoffeeBeanBlack,
              "light-black"
            )}
            {renderBeans(
              slide.beans.light.color,
              slide.imgCoffeeBeanColor,
              "light-color"
            )}
            {renderBeans(
              slide.beans.light.white,
              slide.imgCoffeeBeanWhite,
              "light-white"
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="bean-category intensity">
        <p>Intensywność:</p>
        <div className="bean-category-wrapper">
          {renderBeans(
            slide.beans.intensity.black,
            slide.imgCoffeeBeanBlack,
            "intensity-black"
          )}
          {renderBeans(
            slide.beans.intensity.color,
            slide.imgCoffeeBeanColor,
            "intensity-color"
          )}
          {renderBeans(
            slide.beans.intensity.white,
            slide.imgCoffeeBeanWhite,
            "intensity-white"
          )}
        </div>
      </div>
    </div>
  );
}

export default CoffeeWrapper;
