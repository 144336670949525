import React from "react";

function CoffeeLightBox({ lightBox }) {
  if (!lightBox) {
    return null; // Do not render anything if no light are provided
  }
  return (
    <div class="subTxt">
      <p class="title">{lightBox.header}</p>
      <p>{lightBox.text}</p>
    </div>
  );
}

export default CoffeeLightBox;
