import React from "react";

function CoffeeAlternativesWrapper({ alternatives }) {
  if (!alternatives) {
    return null; // Do not render anything if no alternatives are provided
  }

  return (
    <div>
      <p>Proponowane metody parzenia kawy</p>
      <div className="alternativesBoxWrapper">
        <div>
          <img
            src={alternatives.imgAlternatives1}
            alt="Alternative description"
          />
          <p>{alternatives.alternativeDesc1}</p>
        </div>
        <div>
          <img
            src={alternatives.imgAlternatives2}
            alt="Alternative description"
          />
          <p>{alternatives.alternativeDesc2}</p>
        </div>
        <div>
          <img
            src={alternatives.imgAlternatives3}
            alt="Alternative description"
          />
          <p>{alternatives.alternativeDesc3}</p>
        </div>
        <div>
          <img
            src={alternatives.imgAlternatives4}
            alt="Alternative description"
          />
          <p>{alternatives.alternativeDesc4}</p>
        </div>
        <div>
          <img
            src={alternatives.imgAlternatives5}
            alt="Alternative description"
          />
          <p>{alternatives.alternativeDesc5}</p>
        </div>
        {alternatives.imgAlternatives6 ? (
          <div>
            <img
              src={alternatives.imgAlternatives6}
              alt="Alternative description"
            />
            <p>{alternatives.alternativeDesc6}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CoffeeAlternativesWrapper;
