import React, { useState, useRef, useEffect } from "react";
import "../assets/styles/coffeSlider.css";
import CoffeeWrapper from "./CoffeeWrapper";
import CoffeeAlternativesWrapper from "./CoffeeAlternativesWrapper";
import CoffeeLightBox from "../components/CoffeeLightBox";
import CoffeSliderProd from "../components/CoffeeSliderProd";

const SliderCoffeeSection = ({ slides, slide, index, slideAlt }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesRef = useRef([]);
  const paginationRef = useRef([]);

  const changeSlide = (index) => {
    const totalSlides = slides.length;
    let newCurrent = ((index % totalSlides) + totalSlides) % totalSlides; // Ensures positive indices

    setCurrentSlide(newCurrent);

    // Reset styles for all slides
    slidesRef.current.forEach((slide) => {
      slide.classList.remove("slider-slide-active", "middleBig");
      slide.style.display = "none"; // Ensures slides are hidden
    });

    // Determine visible slides and set classes and styles
    const visibleSlides = [
      newCurrent,
      (newCurrent + 1) % totalSlides,
      (newCurrent + 2) % totalSlides,
    ];

    visibleSlides.forEach((slideIndex, i) => {
      const slide = slidesRef.current[slideIndex];
      slide.classList.add("slider-slide-active");
      slide.style.display = "block"; // Make slide visible
      slide.style.gridColumn = i + 1; // Assign grid column based on visible position
      slide.style.opacity = "1";

      if (i === 1) {
        // Apply special styling to the middle slide
        slide.classList.add("middleBig");
      }
    });

    // Update pagination
    paginationRef.current.forEach((dot, idx) => {
      dot.classList.remove("slider-pagination-element-active");
    });
    paginationRef.current[newCurrent].classList.add(
      "slider-pagination-element-active"
    );
  };

  useEffect(() => {
    changeSlide(0); // Initialize slider with the first slide active
  }, []);

  return (
    <div className="coffeeSectionWrapper">
      <div id="mainSliderCoffeeSection" className="slider">
        <div className="slider-slides-cnt">
          {slides.map((slide, index) => (
            <div
              key={index}
              className="element slider-slide"
              ref={(el) => (slidesRef.current[index] = el)}
            >
              <div className="articleWrapper">
                <div className="desktopImg">
                  <div className="headerWrapper">
                    <img src={slide.image} alt={slide.alt} />
                    <div class="strenghtBox">
                      <img
                        className="name"
                        src={slide.imageName}
                        alt={slide.alt}
                      />
                      <div class="attr">
                        <CoffeeWrapper slide={slide} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="txt">
                  <h3>{slide.title}</h3>
                  <p>{slide.description}</p>
                </div>
                <div class="alternativesBox">
                  <CoffeeAlternativesWrapper
                    alternatives={slide.alternatives}
                  />
                </div>
                <CoffeeLightBox lightBox={slide.lightBox} />
                <CoffeSliderProd coffeeProd={slide.coffeeProd} />
              </div>
            </div>
          ))}
        </div>
        <div className="slider-nav">
          <button
            className=" slider-button-prev"
            onClick={() => changeSlide(currentSlide - 1)}
          >
            &lt;
          </button>
          <button
            className=" slider-button-next"
            onClick={() => changeSlide(currentSlide + 1)}
          >
            &gt;
          </button>
        </div>
        <ul className="slider-pagination">
          {slides.map((_, index) => (
            <li
              key={index}
              className={`slider-pagination-element ${
                currentSlide === index ? "slider-pagination-element-active" : ""
              }`}
              ref={(el) => (paginationRef.current[index] = el)}
              onClick={() => changeSlide(index)}
            >
              <button className="slider-pagination-button">{index + 1}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SliderCoffeeSection;
