import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

function CoffeSliderProd({ coffeeProd }) {
  if (!coffeeProd) {
    return null; // Do not render anything if no light are provided
  }

  return (
    <div class="coffeeProd">
      <Link target="_blank" to={coffeeProd.href}>
        <div className="coffeeProdWrapper">
          <div className="coffeProdWrapperContainer">
            {" "}
            <img src={coffeeProd.image} alt={coffeeProd.alt} />
            <span>{coffeeProd.txt}</span>
          </div>
        </div>
      </Link>
      {coffeeProd.href2 ? (
        <Link target="_blank" to={coffeeProd.href2}>
          <div className="coffeeProdWrapper">
            <div className="coffeProdWrapperContainer">
              {" "}
              <img src={coffeeProd.image2} alt={coffeeProd.alt2} />
              <span>{coffeeProd.txt2}</span>
            </div>
          </div>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

export default CoffeSliderProd;
